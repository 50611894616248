.bookings_container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookings_card_container {
  border: 1px solid gray;
  border-radius: 10px;
  width: 100%;
  box-shadow: inset;
  max-width: 800px;
}

@media screen and (min-width: 768px) {
  .bookings_container {
    padding: 20px 50px;
  }
}

.serviceCard_container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--gray1);
  height: 200px;
  width: 150px;
  cursor: pointer;
}

.serviceCard_container:hover {
  .serviceCard_text {
    background-color: var(--pink160);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.serviceCard_image {
  flex-basis: 50%;
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.serviceCard_text {
  flex-basis: 50%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  padding: 30px 10px;
  display: block;
}

.serviceCard_icon {
  /* flex-basis: 50%; */
  width: 100%;
  height: 200px;
  color: var(--gray4);
}

.serviceCard_text_bg {
  flex-basis: 10%;
  background-color: var(--pink1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media screen and (min-width: 568px) {
  .serviceCard_container {
    height: 250px;
  }

  .serviceCard_text {
    font-size: 14px;
  }
  .serviceCard_image {
    height: 150px;
  }
}

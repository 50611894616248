.servicesSelection_container {
  padding: 10px;
  margin: 10px 20px 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: var(--gray1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
}

.servicesSelection_text_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.servicesSelection_text_container:hover {
  background-color: var(--pink160);
  border-radius: 10px;
}

.servicesSelection__text_name {
  font-size: 14px;
  font-family: "Manrope" sans-serif;
  font-weight: 500;
  color: var(--blue1);
}

.servicesSelection_text_price {
  font-size: 14px;
  font-family: "Manrope" sans-serif;
  font-weight: 400;
  color: var(--blue1);
  text-decoration: italic;
}

.servicesSelection_button_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 16px;
}

.servicesSelection_button {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  background-color: var(--pink160);
  font-family: "Manrope" sans-serif;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.servicesSelection_row_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.servicesSelection__no_service_text {
  margin: 10px 20px;
  display: block;
  font-size: 24px;
  font-family: "Manrope" sans-serif;
  font-weight: 500;
  color: var(--black);
}

.servicesSelection_disclaimer_container {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  white-space: nowrap;
  display: block;
  margin: 14px 0;
  padding: 0 20px;
}

.servicesSelection_disclaimer_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  margin: 0;
  white-space: normal;
}

@media screen and (min-width: 568px) {
  .servicesSelection__text_name {
    font-size: 16px;
  }
  .servicesSelection_text_price {
    font-size: 15px;
  }
}

@media screen and (min-width: 786px) {
  .servicesSelection__text_name {
    font-size: 20px;
  }
  .servicesSelection_row_container {
    flex-direction: row;
  }
  .servicesSelection_container {
    width: 50%;
  }
}

.customModal_container {
  opacity: 100;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #00000040;
  display: flex;
  align-items: flex-start;
  z-index: 30;
  padding: 0;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.customModal_hide {
  opacity: 0;
  height: 0;
  width: 0;
}

.customModal_container_center {
  align-items: center;
}

.customModal_transition {
  transition: all 300ms ease-in-out;
}

.customModal_show_values_container {
  opacity: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  justify-content: center;
  margin: 8px 20px;
  max-height: 80%;
  max-width: 500px;
  width: 100%;
  z-index: 40;
  border-radius: 8px;
  transform: translateY(0);
  padding: 10px;
}

.customModal_hide_values_container {
  opacity: 0;
  height: 0;
}

@media screen and (min-width: 468px) {
  .customModal_container {
    padding: 20px;
  }
}

@media screen and (min-width: 768) {
  .customModal_show_values_container {
    border-radius: 12px;
  }
}

.epicenter_works_container {
  padding: 12px 68px 90px;
  background: #fff9f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.epicenter_works_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: var(--black1);
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-bottom: 61px;
}

.epicenter_works_flow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.epicenter_works_left_dot {
  position: absolute;
  top: 123px;
  z-index: 2;
  background: var(--black1);
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.epicenter_works_right_dot {
  position: absolute;
  bottom: 28px;
  z-index: 2;
  background: var(--black1);
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.epicenter_works_divider {
  border-left: 1px solid var(--black1);
  position: absolute;
  top: 130px;
  bottom: 40px;
}

@media screen and (min-width: 1150px) {
  .epicenter_works_container {
    padding: 100px 99px 110px 99px;
  }
  .epicenter_works_flow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .epicenter_works_left_dot {
    left: 99px;
    top: 50%;
  }

  .epicenter_works_right_dot {
    right: 99px;
    top: 50%;
    bottom: 50%;
  }

  .epicenter_works_divider {
    border-bottom: 1px solid var(--black1);
    border-left: 0;
    left: 101px;
    bottom: 50%;
    top: 51.2%;
    right: 101px;
  }

  .epicenter_works_title {
    font-size: 40px;
    line-height: 60px;
  }
}

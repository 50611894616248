.bookingTabs_container {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  padding: 20px 10px 0;
  border-bottom: 2px solid var(--blue1);
  margin-bottom: 10px;
}

.bookingTabs_tab {
  padding: 10px;
  cursor: pointer;
}

.bookingTabs_selected_tab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--gray1);
}

.bookingTabs_tab_text {
  font-size: 14px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  color: #000000;
}

.bookingTabs_selected_tab_text {
  color: var(--blue1);
}

@media screen and (min-width: 568px) {
  .bookingTabs_container {
    gap: 10px;
  }

  .bookingTabs_tab_text {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .bookingTabs_container {
    gap: 20px;
  }
}

.procedure_container {
  padding: 60px 20px;
  background-color: #efe3de;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.procedure_image {
  height: 375px !important;
  width: 375px !important;
  object-fit: cover;
  margin-bottom: 63px;
}

.procedure_text_container {
  display: flex;
  flex-direction: column;
  align-items: normal;
}

.procedure_title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  text-align: center;
  color: var(--black1);
  margin-bottom: 30px;
}

@media screen and (min-width: 900px) {
  .procedure_container {
    padding: 49px 75px 49px 99px;
    flex-direction: row-reverse;
    align-items: center;
  }

  .procedure_title {
    font-size: 40px;
    line-height: 60px;
  }
}

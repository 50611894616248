.sidebar_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f1e6e0;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  padding: 20px 0;
  overflow: hidden;
}

.sidebar_image_container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-right: 30px;
  justify-content: flex-end;
}

.sidebar_image {
  height: 45px;
  width: 40px;
  object-fit: contain;
}

.sidebar_items_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 30px;
}

.sidebar_item {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
  color: var(--black1);
  font-family: "Poppins", sans-serif;
}

.sidebar_item:hover {
  cursor: pointer;
}

.modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
}

.modal-container {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: var(--black);
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  margin: 0 10px;
}

.modal-content {
  margin-top: 10px;
}

.modal-close-icon-container {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--pink1);
}

.modal-close-icon {
  height: 20px;
  width: 20px;
  color: var(--black);
}

.visible {
  display: flex;
}

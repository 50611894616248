.bookingsTechnicians_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin: 10px 15px;
}

.bookingsTechnicians_disclaimer_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  white-space: nowrap;
  display: block;
  margin: 15px 0;
  padding: 0 20px;
}

.bookingsTechnicians_disclaimer_point {
  font-size: 14px;
  font-weight: 500;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  margin: 0;
  white-space: normal;
}

@media screen and (min-width: 568px) {
  .bookingsTechnicians_container {
    gap: 10px;
    margin: 10px 20px;
  }
}

@media screen and (min-width: 768px) {
  .bookingsTechnicians_container {
    gap: 20px;
  }
}

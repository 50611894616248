.epicenter_services_container {
  padding: 50px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff9f2;
}

.epicenter_service_image {
  width: 335px !important;
  height: 373px !important;
  object-fit: cover;
  border-radius: 0 177px;
  margin-bottom: 67px;
}

.epicenter_service_description_container {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  align-items: center;
}

.epicenter_services_heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: var(--black1);
  font-family: "Poppins", sans-serif;
  margin-bottom: 29px;
}

@media screen and (min-width: 900px) {
  .epicenter_services_container {
    padding: 110px 0 110px 99px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .epicenter_service_image {
    width: 50% !important;
    height: 608px !important;
    object-fit: cover;
    border-radius: 0 288px;
    margin-bottom: 0;
  }

  .epicenter_service_description_container {
    margin-left: 94px;
  }

  .epicenter_services_heading {
    font-size: 40px;
    line-height: 60px;
    text-align: start;
  }
}

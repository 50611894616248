.banner_container {
  width: 100%;
  height: 809px;
  background: linear-gradient(
    270deg,
    #fff8f2 2.08%,
    #fff8f2 41.2%,
    rgba(255, 248, 242, 0) 78.95%
  );
}

.image_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner_image {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.banner_text_container {
  position: absolute;
  bottom: 80px;
  left: 20px;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.banner_title_text {
  font-size: 30px;
  font-weight: 700;
  font-family: "Libre Baskerville", serif;
  line-height: 37px;
  color: var(--black1);
  margin-bottom: 30px;
}

.banner_button {
  padding: 10px 20px;
  background: var(--black1);
  text-align: center;
  font-weight: 700;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  width: max-content;
  border-radius: 1px;
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .banner_text_container {
    width: 40%;
    top: 30%;
    left: 101px;
  }
}

@media screen and (min-width: 900px) {
  .banner_container {
    height: 809px;
  }

  .banner_title_text {
    font-size: 55px;
    font-weight: 700;
    line-height: 68px;
    margin-bottom: 71px;
  }

  .banner_button {
    font-size: 18px;
  }
}

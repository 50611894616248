.bookings_header_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 5px;
  margin-bottom: 20px;
}

.left_section {
  display: flex;
  align-items: center;
}

.logo_image {
  width: 69px;
  height: 69px;
}

.title {
  font-size: 17px;
  font-weight: 400;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  text-transform: uppercase;
  margin-left: 8px;
  white-space: nowrap;
}

.bookings_header_text_container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px 10px;
}

.bookings_header_address_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  text-transform: uppercase;
  text-overflow: break-word;
}

.bookings_header_phone_text {
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  color: var(--black1);
  font-family: "Manrope", sans-serif;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .bookings_header_address_text {
    white-space: nowrap;
  }
}

.banner2_container {
  position: relative;
  height: 316px;
}

.banner2_image {
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}

.banner2_text_container {
  position: absolute;
  bottom: 10px;
  left: 12px;
  display: flex;
  flex-direction: column;
}

.banner2_hand_image {
  width: 91px !important;
  height: 72px !important;
  object-fit: contain;
  margin-bottom: 22px;
}

.banner2_text {
  width: 100%;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  line-height: 32px;
  color: white;
}

@media screen and (min-width: 900px) {
  .banner2_container {
    position: relative;
    height: 730px;
  }

  .banner2_text_container {
    left: 99px;
    bottom: 132px;
  }
  .banner2_hand_image {
    width: 80px !important;
    height: 60px !important;
  }

  .banner2_text {
    font-size: 55px;
    font-weight: 700;
    line-height: 82px;
    width: 70%;
  }
}

.contact_container {
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff9f2;
}

.contact_image {
  width: 350px !important;
  height: 350px !important;
  object-fit: cover;
  border-radius: 0px 177px;
  margin-bottom: 60px;
}

.contact_form_container {
  display: flex;
  flex-direction: column;
}

.contact_text {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: var(--black1);
  text-align: start;
  margin-bottom: 25px;
}

.contact_phone_container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact_phone_inner {
  margin-left: 19px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.contact_phone_inner_heading {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 6%;
  font-family: "Poppins", sans-serif;
  color: var(--black1);
  margin-bottom: 5px;
}

.contact_phone_inner_subheading {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: var(--black1);
  font-family: "Poppins", sans-serif;
}

.form_input_container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.contact_value_container {
  display: flex;
  flex-direction: column;
}

.form_input {
  padding: 13px 16px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 21px;
  color: #1e1325;
  opacity: 0.6;
  background: #ffffff;
  margin-bottom: 25px;
  border: 0.572754px solid rgba(30, 19, 37, 0.57);
}

.form_text_area {
  padding: 15px 19px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 21px;
  color: #1e1325;
  opacity: 0.6;
  background: #ffffff;
  margin-bottom: 45px;
  border: 0.572754px solid rgba(30, 19, 37, 0.57);
}

.contact_button {
  padding: 10px 40px;
  background-color: var(--black1);
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  font-family: "Manrope", sans-serif;
  color: #ffffff;
  width: max-content;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 1px;
  margin-bottom: 65px;
}

@media screen and (min-width: 1100px) {
  .contact_container {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 115px 99px;
  }

  .contact_image {
    width: 563px !important;
    height: 639px !important;
    border-radius: 0 288px;
  }

  .contact_text {
    font-size: 40px;
    line-height: 60px;
  }

  .contact_phone_inner_heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .contact_phone_inner_subheading {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }

  .form_input_container {
    flex-direction: row;
  }

  .contact_value_container {
    width: 50%;
    flex-direction: column;
  }

  .form_input {
    font-size: 16px;
    line-height: 24px;
    width: 80%;
    margin-right: 23px;
  }

  .form_text_area {
    font-size: 16px;
    width: 89%;
    margin-bottom: 50px;
  }

  .contact_button {
    font-size: 18px;
    line-height: 24px;
  }
}

.showTimings_container {
  margin: 10px 20px;
}

.showTimings_heading {
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  width: 100%;
  text-align: center;
}

.showTimings_timings_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  margin: 10px 0;
}

.showTimings_button {
  border: none;
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.showTimings_button:hover {
  background-color: var(--pink160);
}

@media screen and (min-width: 568px) {
  .showTimings_heading {
    font-size: 20px;
  }
  .showTimings_button {
    font-size: 14px;
  }
}

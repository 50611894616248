.bookingsSearch_container {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--gray2);
  padding: 5px 8px;
  width: 200px;
  margin-left: 20px;
  border-radius: 8px;
}

.booksSearch_input_box {
  background-color: var(--gray3);
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: none;
}

.bookingsSearch_icon {
  height: 20px;
  width: 20px;
  color: black;
}

@media screen and (min-width: 568px) {
  .bookingsSearch_container {
    padding: 5px 10px;
    width: 250px;
  }

  .booksSearch_input_box {
    padding: 10px;
    font-size: 14px;
  }

  .booksSearch_input_box::placeholder {
    font-size: 14px;
  }
}
